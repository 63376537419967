*{
 margin: 0px;
 padding: 0px;   
}

:root{
  --fc-button-hover-bg-color: #211D1C;
   --fc-button-active-bg-color: #211D1C;
  /* --fc-button-bg-color: #f84f00;  */
}

.fc .fc-button-primary{
  color: #636578;
  background-color: #fff;
  border-color: #211D1C;
}

